import {React, useState, useEffect} from "react";
import Image from "next/image";
import styles from "./fixedBotCta.module.scss";
import classNames from "classnames";
import ActivationFlowModal from "../ActivationFlowModal/ActivationFlowModal";
import tesla from "../../../public/images/tesla/tesla-front.png";
import {afButtonClicked} from "../../../lib/appsflyer";
import {generateOneLinkURL} from "../../../lib/appsflyerOneLink";

export default function FixedBotCTA() {
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [oneLinkUrl, setOneLinkUrl] = useState("#");

  useEffect(() => {
    const url = generateOneLinkURL();
    setOneLinkUrl(url);
  }, []);

  const onHideActivationFlowModal = () => {
    setShowActivationModal(false);
  };

  return (
    <div className={styles.fixedBotCTA}>
      {showActivationModal && (
        <ActivationFlowModal
          show={() => {
            setShowActivationModal(true);
          }}
          onHide={() => {
            onHideActivationFlowModal();
          }}
        />
      )}
      <div className={styles.ev}>
        <Image src={tesla} width={76} height={55} alt="" quality={85} />
      </div>
      <div className={styles.cta}>
        <span className={styles.lineOne}>Subscribe to an EV.</span>
        <span className={styles.lineTwo}>Get started today.</span>
      </div>
      <div className={styles.btn}>
        <a
          href={oneLinkUrl}
          onClick={() => afButtonClicked("mobile only", "download app clicked")}
          className={classNames(styles.getTheApp, "d-md-none")}
        >
          Get the app
        </a>
        <button
          onClick={() => {
            setShowActivationModal(true);
          }}
          className={classNames("d-none d-md-inline-block", styles.getTheApp)}
        >
          Get the app
        </button>
      </div>
    </div>
  );
}
