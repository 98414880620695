import titleAndRegistration from "./Icon=Title_&_Reg.svg";
import digital from "./Icon=100_digital.svg";
import debtFree from "./Icon=Debt_Free.svg";
import freeChargingKit from "./Icon=Free_Charging_Kit.svg";
import mileage from "./Icon=Mileage.svg";
import negotiationFree from "./Icon=Negotiation_Free.svg";
import noWaitTime from "./Icon=No_Wait_time.svg";
import payCreditCard from "./Icon=Pay_Credit_Card.svg";
import returnAnytime from "./Icon=Return_Anytime.svg";
import roadside from "./Icon=Roadside.svg";
import routineMaintenance from "./Icon=Routine_Maintenance.svg";
import tires from "./Icon=Tires.svg";
import zeroCreditImpact from "./Icon=Zero_Credit_Impact.svg";

export {
  titleAndRegistration,
  digital,
  debtFree,
  freeChargingKit,
  mileage,
  negotiationFree,
  noWaitTime,
  payCreditCard,
  returnAnytime,
  roadside,
  routineMaintenance,
  tires,
  zeroCreditImpact,
};
