import classNames from "classnames";
import {useContext} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import Image from "next/image";
import styles from "./GreenerSection.module.scss";

export default function GreenerSection({className}) {
  const context = useContext(ButterCMSContentContext);
  const {
    heading = "",
    subheading = "",
    background_image,
    mobile_background_image,
    vehicle_image,
  } = context.greener_section || context.greener_future_section;

  return (
    <section className={classNames(styles.section, className)}>
      <div className={classNames("container", styles.card)}>
        <div className={styles.content}>
          <div className={styles.title}>{heading}</div>
          <div className={styles.text}>{subheading}</div>
        </div>
        <div className={styles.car}>
          <div className={styles.imageWrap}>
            {vehicle_image ? (
              <Image alt="" src={vehicle_image} height={446} width={1200} />
            ) : (
              <Image
                alt=""
                src="/images/GreenerFutureSection/green-car.png"
                height={744}
                width={2000}
              />
            )}
          </div>
          <div className={styles.blob} />
        </div>
        <div
          className={styles.background}
          style={{
            "--greener-section-bg-image": `url(${
              background_image || "/images/GreenerFutureSection/green-bg.jpg"
            })`,
            "--greener-section-mobile-bg-image": `url(${
              mobile_background_image ||
              "/images/GreenerFutureSection/green-bg-mobile.jpg"
            })`,
          }}
        />
      </div>
    </section>
  );
}
