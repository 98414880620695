import {debounce} from "lodash";
import {useEffect, useState} from "react";
import {TYPE_THEME} from "../../lib/constants";

export default function useThemeOnScroll({
  enabled = true,
  threshold = 750,
} = {}) {
  const [theme, setTheme] = useState(
    enabled ? TYPE_THEME.TRANSPARENT : TYPE_THEME.LIGHT
  );

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      const isAboveThreshold = document.scrollingElement.scrollTop > threshold;
      setTheme(isAboveThreshold ? TYPE_THEME.LIGHT : TYPE_THEME.TRANSPARENT);
    }, 10);

    if (enabled) {
      window.addEventListener("scroll", debouncedHandleScroll);
    }

    return () => {
      if (enabled) {
        window.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return theme;
}
