import {useContext} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import VideoSection from "../../Partners/VideoSection/VideoSection";
import Image from "next/image";
import parse from "html-react-parser";
import s from "./whatIsSub.module.scss";

export default function WhatIsSub() {
  const {whatissub} = useContext(ButterCMSContentContext);
  const {title, content, feature} = whatissub || {};

  return (
    <div id="whatIsSub" className={s.section}>
      <div className="container">
        <div className={s.title}>{parse(title)}</div>
        <div className={s.content}>{parse(content)}</div>
        <VideoSection fromHp={true} />
        <div className={s.partnerFeatures}>
          <h3 className={s.featureHeading}>Featured In</h3>
          <div className="row">
            {feature.map(({image, image_alt_text}) => (
              <div className={s.logo} key={image}>
                <Image
                  alt={image_alt_text}
                  src={image}
                  objectFit="contain"
                  objectPosition="center center"
                  height={60}
                  width={150}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
