import {useContext} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import ContactFormSection from "../../NewVDP/ContactFormSection/ContactFormSection";
import styles from "./ContactSection.module.scss";

export default function ContactSection() {
  const {contact_section} = useContext(ButterCMSContentContext);

  return (
    <div className={styles.background}>
      <ContactFormSection
        className={styles.contactForm}
        content={contact_section}
      />
    </div>
  );
}
