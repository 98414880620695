export function afButtonClicked(label, eventName) {
  window.AF?.("pba", "event", {
    eventType: "EVENT",
    eventValue: {
      category: "button_clicks",
      label: label,
    },
    eventName: eventName,
  });
}
