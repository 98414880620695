import styles from "./HomeContent.module.scss";
import Header from "./Header/Header";
import HeroSection from "./HeroSection/HeroSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import GreenerSection from "../Shared/GreenerSection/GreenerSection";
import VehiclesAvailableSection from "./VehiclesAvailableSection/VehiclesAvailableSection";
import AllDigitalSection from "./AllDigitalSection/AllDigitalSection";
import ContactSection from "./ContactSection/ContactSection";
import Footer from "../GlobalFooter/Footer";
import FixedBotCTA from "../Shared/FixedBotCTA/FixedBotCTA";
import DisclosureSection from "./DisclosureSection/DisclosureSection";
import WhatIsSub from "./WhatIsSub/WhatIsSub";
import LoanFreeAccess from "./LoanFreeAccess/LoanFreeAccess";
import SubscriptionBenefitsSection from "../SubscriptionBenefitsSection.js/SubscriptionBenefitsSection";

export default function HomeContent({showPricingChanges, showPromoBanner}) {
  return (
    <div className={styles.homeContent}>
      <Header showPromoBanner={showPromoBanner} />
      <HeroSection showHomepageReorder />
      <WhatIsSub />
      <SubscriptionBenefitsSection />
      <VehiclesAvailableSection showPricingChanges={showPricingChanges} />
      <DisclosureSection fromHp={true} />
      <LoanFreeAccess />
      <div className={styles.reorderDigital}>
        <AllDigitalSection />
      </div>
      <TestimonialSection fromPartners={true} />
      <GreenerSection />
      <ContactSection />
      <Footer />
      <div className="d-block d-lg-none">
        <FixedBotCTA />
      </div>
    </div>
  );
}
