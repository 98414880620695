import {formatCurrency} from "./numbers";

export function getMonthlyPayment(pricingOption) {
  if (typeof pricingOption?.monthly !== "undefined") {
    return formatCurrency(pricingOption?.monthly / 100);
  }

  return "";
}

export function getSecurityDeposit(pricingOption) {
  if (typeof pricingOption?.deposit !== "undefined") {
    return formatCurrency(pricingOption.deposit / 100);
  }

  return "";
}

export function getStartFee(pricingOption) {
  if (typeof pricingOption?.startFee !== "undefined") {
    return formatCurrency(pricingOption.startFee / 100);
  }

  return "";
}
