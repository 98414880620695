import {useContext, useState} from "react";
import {template} from "../../../lib/butter-utils";
import {getSecurityDeposit, getStartFee} from "../../../lib/utils/service";
import ButterCMSContentContext from "../../context/butterCMSContent";
import PaymentReservationContext from "../../context/paymentReservation";
import classNames from "classnames";
import parse, {domToReact} from "html-react-parser";
import styles from "./DisclosureSection.module.scss";
import ExpandingToNewStatesModal from "../../ExpandingToNewStates/ExpandingToNewStatesModal";

export default function DisclosureSection({fromHp}) {
  const {disclosure_section} = useContext(ButterCMSContentContext);
  const {disclosures = []} = disclosure_section || {};
  const {actualLowestPricingOption} = useContext(PaymentReservationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const templateData = {
    lowMonthlyPaymentStartFee_Model3SRP: getStartFee(actualLowestPricingOption),
    securityDeposit: getSecurityDeposit(actualLowestPricingOption),
  };

  return (
    <div className={styles.background}>
      <ExpandingToNewStatesModal
        onHide={() => setIsModalOpen(false)}
        show={isModalOpen}
      />
      <div
        className={classNames(fromHp ? "container pt-3 pt-lg-0" : "container")}
      >
        {disclosures.map(({text, rich_text}) => (
          <div key={rich_text || text} className={styles.disclosure}>
            {parse(template(rich_text || text)(templateData), {
              replace: ({attribs, children}) => {
                if (attribs?.id === "expandLocationsModal") {
                  return (
                    <button
                      className={styles.expandLocationsModalLink}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {domToReact(children)}
                    </button>
                  );
                }
              },
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
