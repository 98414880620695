import classNames from "classnames";
import Image from "next/image";
import {useContext} from "react";
import parse from "html-react-parser";
import ButterCMSContentContext from "../context/butterCMSContent";
import * as icons from "./icons";
import styles from "./SubscriptionBenefitsSection.module.scss";

export default function SubscriptionBenefitsSection() {
  const {subscription_benefits_section, pick_the_plan_section} = useContext(
    ButterCMSContentContext
  );
  const {disclosure} = pick_the_plan_section || {};
  const {
    heading = "All Autonomy subscriptions include:",
    features = [
      {
        feature: "1,000 Miles Per Month",
        disclaimer: "($0.25/add'l mi.)",
        icon: icons.mileage,
      },
      {
        feature: "Title, Reg., & Licensing",
        disclaimer: "(Est. $600 Value)",
        icon: icons.titleAndRegistration,
      },
      {
        feature: "Routine Maintenance",
        disclaimer: "(Est. $2,350 Value)",
        icon: icons.routineMaintenance,
      },
      {
        feature: "Roadside Assistance",
        disclaimer: "(Limitations apply)",
        icon: icons.roadside,
      },
      {
        feature: "Free Charging Kit",
        disclaimer: "(Est. $230 Value)",
        icon: icons.freeChargingKit,
      },
      {feature: "Pay with a Credit Card", icon: icons.payCreditCard},
      {
        feature: "Zero Credit Impact",
        icon: icons.zeroCreditImpact,
      },
      {
        feature: "Return Anytime",
        disclaimer: "(No fees)",
        isMobileDisclaimer: true,
        icon: icons.returnAnytime,
      },
    ],
  } = subscription_benefits_section || {};

  return (
    <div className={styles.section}>
      <div className="container">
        <div
          className={classNames(styles.card, {
            [styles.missingDisclosure]: !disclosure,
          })}
        >
          <div className={styles.heading}>{heading}</div>
          <div className={classNames(styles.features, "row")}>
            {features.map(({feature, shortName, disclaimer, icon}) => (
              <div key={feature} className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div
                  className={classNames(
                    styles.feature,
                    "d-flex flex-lg-column align-items-center"
                  )}
                >
                  <div className={styles.featureIcon}>
                    <Image alt="" src={icon} />
                  </div>
                  <div>
                    <div
                      className={classNames(
                        styles.featureName,
                        "d-none d-lg-block"
                      )}
                    >
                      {feature}
                    </div>
                    <div
                      className={classNames(styles.featureName, "d-lg-none")}
                    >
                      {shortName || feature}
                    </div>
                    {disclaimer && (
                      <div className={styles.featureDisclaimer}>
                        {disclaimer}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {disclosure && (
            <div className={styles.disclosure}>{parse(disclosure)}</div>
          )}
        </div>
      </div>
    </div>
  );
}
