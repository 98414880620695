import {useContext} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import Image from "next/image";
import classNames from "classnames";
import s from "./loanFreeAccess.module.scss";

export default function LoanFreeAccess() {
  const {loan_free_access} = useContext(ButterCMSContentContext);
  const {title, subtitle, desktop_image, mobile_image} = loan_free_access || {};

  return (
    <div className={s.section}>
      <div className="container">
        <div className={s.title}>{title}</div>
        <div className={s.subtitle}>{subtitle}</div>
        <div className={classNames(s.mobileImage, "d-lg-none")}>
          <Image
            src={mobile_image}
            alt="Loan free access to an EV"
            width={1444}
            height={780}
            layout="responsive"
          />
        </div>
        <div className={classNames(s.image, "d-none d-lg-block")}>
          <Image
            src={desktop_image}
            alt="Loan free access to an EV"
            width={3579}
            height={870}
            layout="responsive"
            sizes="100vw"
          />
        </div>
      </div>
    </div>
  );
}
