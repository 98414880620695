import {useEffect, useState} from "react";
import useThemeOnScroll from "../../hooks/useThemeOnScroll";
import NavbarReimagined from "../../Navbar/NavbarReimagined";
import styles from "./Header.module.scss";
import PurchaseBanner from "../../Navbar/purchase-banner/purchase-banner";
import {useFlags} from "launchdarkly-react-client-sdk";

export default function Header() {
  const [showOnMount, setShowOnMount] = useState(false);
  const {showPurchaseBanner} = useFlags();
  const theme = useThemeOnScroll();
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  useEffect(() => {
    setShowOnMount(true);
  }, []);

  function handleMobileMenuOpened(opened) {
    setIsMobileMenuOpened(opened);
  }

  if (showOnMount && showPurchaseBanner) {
    return (
      <>
        {!isMobileMenuOpened && <PurchaseBanner />}
        <div className={styles.navbarWithBannerWrapper}>
          <NavbarReimagined
            onMobileMenuOpen={handleMobileMenuOpened}
            theme={theme}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <NavbarReimagined
        isFixed
        onMobileMenuOpen={handleMobileMenuOpened}
        theme={theme}
      />
    </>
  );
}
