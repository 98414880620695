import classNames from "classnames";
import dynamic from "next/dynamic";
import React, {useContext, useEffect, useRef, useState} from "react";
import Image from "next/image";
import Link from "next/link";
import ButterCMSContentContext from "../../context/butterCMSContent";
import DownloadButton from "../DownloadButton/DownloadButton";
import HeroArrow from "../../../public/images/hpReorder/heroArrow.svg";
import styles from "./HeroSection.module.scss";
import {useLgScreenMediaQuery} from "../../hooks/usePresetMediaQuery";

const ReactPlayer = dynamic(() => import("react-player/lazy"), {
  ssr: false,
});

export default function HeroSection({showHomepageReorder = false}) {
  const [showVideos, setShowVideos] = useState(false);
  const lgScreenMediaQuery = useLgScreenMediaQuery();

  const {hero_section} = useContext(ButterCMSContentContext);
  const {
    cta_text,
    heading,
    subheading,
    desktop_background_video_link,
    mobile_background_video_link,
  } = hero_section || {};
  const sectionRef = useRef(null);
  const videoRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShowVideos(true);
    }, 1000);
  });

  useEffect(() => {
    if (!showVideos) {
      return;
    }

    // NOTE: pure CSS side-effect, must avoid debounced
    function updateOnScroll() {
      const sectionEl = sectionRef.current;
      const contentEl = contentRef.current;
      const isMobileShowing = !lgScreenMediaQuery;
      const videoEl = videoRef.current;

      if (sectionEl) {
        const scrollTop = document.scrollingElement.scrollTop;
        const sectionElHeight = sectionEl.offsetHeight;

        let scrollTarget = 0.7;

        if (scrollTop > sectionElHeight * scrollTarget) {
          videoEl.style.opacity = 0;
          contentEl.style.opacity = 0;
          contentEl.style.position = "";
        } else if (scrollTop > 0) {
          videoEl.style.opacity = 0.7 - scrollTop / sectionElHeight;

          const translateChange = scrollTop * 1.1;
          const scaleChange = Math.min(
            ((sectionElHeight - scrollTop) / sectionElHeight) * 0.8,
            1
          ).toFixed(4);
          videoEl.style.transform = `translateY(${translateChange}px) scale(${scaleChange})`;

          contentEl.style.opacity = 1;
          contentEl.style.position = "fixed";
        } else {
          if (isMobileShowing) {
            videoEl.style.transform = `translateY(0px) scale(1.0)`;
          } else {
            videoEl.style.transform = `translateY(0px) scale(1.2)`;
          }

          videoEl.style.opacity = 0.7;
          contentEl.style.opacity = 1;
          contentEl.style.position = "";
        }
      }
    }

    updateOnScroll();

    window.addEventListener("scroll", updateOnScroll);
    return () => {
      window.removeEventListener("scroll", updateOnScroll);
    };
  }, [showVideos]);

  return (
    <>
      <section className={styles.section} ref={sectionRef}>
        {showVideos &&
          (lgScreenMediaQuery ? (
            <div className={styles.backgroundImage} ref={videoRef}>
              <ReactPlayer
                className="react-player-w-file"
                autoPlay={true}
                controls={false}
                loop={true}
                muted={true}
                playing={true}
                playsinline={true}
                url={desktop_background_video_link}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <div className={styles.backgroundImage} ref={videoRef}>
              <ReactPlayer
                className="react-player-w-file"
                autoPlay={true}
                controls={false}
                loop={true}
                muted={true}
                playing={true}
                playsinline={true}
                url={mobile_background_video_link}
                height="100%"
                width="100%"
              />
            </div>
          ))}
        <div
          className={classNames(styles.contentContainer, "container")}
          ref={contentRef}
        >
          <div className={styles.content}>
            <h1 className={styles.heading}>{heading}</h1>
            <div className={styles.subheading}>
              {subheading ? (
                subheading
              ) : (
                <span>
                  <b>Welcome to Autonomy.</b>
                  <br />
                  <br />
                  Quite possibly the easiest, most flexible way to get into a
                  Tesla Model 3. No endless paperwork. No negotiation. And no
                  long-term commitment. Pay month to month. Drive the car as
                  long as you like. Return it when you&#39;re done. That&#39;s
                  Autonomy.
                  <br />
                  <br />
                  Your all-in monthly payment includes title, registration,
                  maintenance, routine tire replacement, charging kit, and color
                  preference at no additional charge.*
                </span>
              )}
            </div>
            <div className="d-none d-md-inline-block">
              <DownloadButton>{cta_text}</DownloadButton>
            </div>
          </div>
        </div>
        {showHomepageReorder && (
          <Link href="#whatIsSub" legacyBehavior={true}>
            <a className={classNames("d-none d-md-block", styles.learnMore)}>
              <div className={styles.lmText}>Learn more</div>
              <div className={styles.lmArrow}>
                <Image
                  src={HeroArrow}
                  width={16}
                  height={26}
                  alt="learn more"
                  layout="fixed"
                />
              </div>
            </a>
          </Link>
        )}
      </section>
    </>
  );
}
