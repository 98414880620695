import {useMediaQuery} from "react-responsive";
import styles from "../../styles/exportedVariables.module.scss";

export const useXXLScreenMediaQuery = () =>
  useMediaQuery({minWidth: parseInt(styles.breakpoint_xxl)});

export const useXLScreenMediaQuery = () =>
  useMediaQuery({minWidth: parseInt(styles.breakpoint_xl)});

export const useLgScreenMediaQuery = () =>
  useMediaQuery({minWidth: parseInt(styles.breakpoint_lg)});

export const useMdScreenMediaQuery = () =>
  useMediaQuery({minWidth: parseInt(styles.breakpoint_md)});

export const useSmScreenMediaQuery = () =>
  useMediaQuery({minWidth: parseInt(styles.breakpoint_sm)});
