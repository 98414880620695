import {getClient, getLDContext} from "../lib/launchDarkly";
import {ButterCMSContentContextProvider} from "../components/context/butterCMSContent";
import HomeContent from "../components/Home_Redesign/HomeContent";
import {getPageByTitle} from "../lib/butter-api";
import {revalidateBySeconds} from "../utils/page";

export async function getStaticProps({preview}) {
  const client = await getClient();

  const showPricingChanges = await client.variation(
    "pricing-changes-v-1-1",
    getLDContext(),
    false
  );

  const showPromoBanner = await client.variation(
    "show-price-match-banner",
    getLDContext(),
    false
  );

  const pageContent = await getPageByTitle("home-v4", preview);

  return {
    props: {
      pageContent,
      showPricingChanges,
      showPromoBanner,
    },
    revalidate: revalidateBySeconds(600),
  };
}

HomePage.backgroundColor = "#000000";
HomePage.usePricingOptions = false;

export default function HomePage({
  pageContent,
  showPricingChanges,
  showPromoBanner,
}) {
  return (
    <ButterCMSContentContextProvider pageContent={pageContent}>
      <HomeContent
        showPricingChanges={showPricingChanges}
        showPromoBanner={showPromoBanner}
      />
    </ButterCMSContentContextProvider>
  );
}
