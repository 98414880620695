import {useContext, useState, useEffect, useRef} from "react";
import ButterCMSContentContext from "../../context/butterCMSContent";
import {debounce} from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "next/image";
import classNames from "classnames";
import styles from "./AllDigitalSection.module.scss";
import DownloadButton from "../DownloadButton/DownloadButton";

export default function AllDigitalSection() {
  const [isAnimating, setIsAnimating] = useState(false);
  const {all_digital_section} = useContext(ButterCMSContentContext);
  const {cta_text, heading, subheading, images} = all_digital_section;
  const {image} = images[0];

  const section = useRef(null);

  const handleScroll = () => {
    const position = window.pageYOffset;

    if (section.current) {
      const animatePosition = section.current.offsetTop - 650;

      if (position > animatePosition) {
        setIsAnimating(true);
      } else {
        setIsAnimating(false);
      }
    }
  };

  useEffect(() => {
    const debouncedScroll = debounce(handleScroll, 25);
    window.addEventListener("scroll", debouncedScroll);
    return () => window.removeEventListener("scroll", debouncedScroll);
  }, []);

  return (
    <section ref={section} className={styles.main}>
      <Row className={styles.container}>
        <Col
          xs={12}
          md={5}
          className={classNames(styles.copy, {
            [styles.animate_copy]: isAnimating,
          })}
        >
          {heading && <h2 className={styles.heading}>{heading}</h2>}
          {subheading && <p className={styles.subheading}>{subheading}</p>}
          {cta_text && (
            <DownloadButton className={styles.download_button}>
              {cta_text}
            </DownloadButton>
          )}
        </Col>
        {image && (
          <Col xs={12} md={7} className={styles.right_col}>
            <div
              className={classNames(styles.image_container, {
                [styles.animate_image]: isAnimating,
              })}
            >
              <Image
                src={image}
                alt=""
                layout="fill"
                objectFit="cover"
                sizes="100vw"
              />
            </div>
          </Col>
        )}
      </Row>
    </section>
  );
}
